
.file-list {
  padding: 20px 10px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  justify-content: start;
  grid-gap: 10px;
}

.file-card {
  width: 200px;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
}

.spinner {
  position: absolute;
  left: 70px;
  top: 80px;
  top: 40px;
  width: 40px !important;
  height: 40px !important;
}
