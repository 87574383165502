/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.input-group-text {
  color: $input-group-addon-color;
  background-color: $input-group-addon-bg;
  border-color: $input-group-addon-border-color;
}
