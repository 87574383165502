/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $headings-color;
}

//
// Horizontal rules
//

hr {
  border-top-color: $hr-border-color;
}

mark,
.mark {
  background-color: $mark-bg;
}


.blockquote-footer {
  color: $blockquote-small-color;
}
