/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

// Image thumbnails
.img-thumbnail {
  background-color: $thumbnail-bg;
  border-color: $thumbnail-border-color;
  @include box-shadow($thumbnail-box-shadow);
}

.figure-caption {
  color: $figure-caption-color;
}
