/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.page-link {
  color: $pagination-color;
  background-color: $pagination-bg;
  border-color: $pagination-border-color;

  &:hover {
    color: $pagination-hover-color;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.page-item {
  &.active .page-link {
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}
