/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.loader {
  @keyframes animation-roll {
    0% {
      transform: translateX(calc(-100vw / 2 - 100%)) rotateZ(0deg);
    }
    100% {
      transform: translateX(calc(100vw / 2 + 100%)) rotateZ(720deg);
    }
  }

  @keyframes animation-jump {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, .9) translateY(0);
    }
    30% {
      transform: scale(.9, 1.1) translateY(-100px);
    }
    50% {
      transform: scale(1.05, .95) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }

  @keyframes animation-shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .animation-roll {
    transform-origin: center center;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-name: animation-roll;
    animation-timing-function: linear;
  }

  .animation-jump {
    transform-origin: bottom;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: animation-jump;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
  }

  .animation-shake {
    animation: animation-shake 0.3s ease-in-out;
  }

  height: 100vh;
  width: 100vw;

  &.middle, .middle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .progress {
    width: 50%;
  }
}
