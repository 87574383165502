/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  color: $body-color;

  @include hover() {
    color: $body-color;
  }

  &:focus,
  &.focus {
    box-shadow: $btn-focus-box-shadow;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  color: $link-color;

  @include hover() {
    color: $link-hover-color;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}
