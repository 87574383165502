/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value, true);
  }
}

.bg-white {
  background-color: $white !important;
}
