/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.toast {
  color: $toast-color;
  background-color: $toast-background-color;
  border-color: $toast-border-color;
}

.toast-header {
  color: $toast-header-color;
  background-color: $toast-header-background-color;
  border-bottom-color: $toast-header-border-color;
}
