/*!
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */


.table-picker-container {
  z-index: 1111;

  @import "../../../../../style/variables.light";

  .table-cell {
    border: 1px solid $gray-700;
    margin: 1px;
    border-radius: 2px;
  }

  .table-container {
    display: grid;
    grid-template-columns: repeat(10, 15px [col-start]);
    grid-template-rows: repeat(8, 15px [row-start]);
  }
}

