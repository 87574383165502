/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.breadcrumb {
  background-color: $breadcrumb-bg;
}

.breadcrumb-item {
  + .breadcrumb-item {
    &::before {
      color: $breadcrumb-divider-color;
    }
  }

  &.active {
    color: $breadcrumb-active-color;
  }
}
