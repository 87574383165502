/*!
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.splitter {
  &.left {
    min-width: 200px;
  }

  &.separator {
    display: flex;
  }
}
