/*!
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@import '../../../../node_modules/codemirror/lib/codemirror';
@import '../../../../node_modules/codemirror/addon/display/fullscreen';
@import '../../../../node_modules/codemirror/addon/fold/foldgutter';
@import '../../../../node_modules/codemirror/addon/dialog/dialog';
@import '../../../../node_modules/codemirror/theme/neat';
@import './one-dark';
@import 'hints';
@import '../../../../node_modules/firacode/distr/fira_code.css';

.CodeMirror {
  font-family: "Fira Code", "Twemoji", Consolas, monaco, monospace;
  letter-spacing: 0.025em;
  line-height: 1.25;
  font-size: 18px;
  height: 100%;
}

.react-codemirror2.file-drag .CodeMirror-cursors {
  visibility: visible;
}

.no-ligatures .CodeMirror {
  //These two properties must be set separately because otherwise node-scss breaks.
  .CodeMirror-line, .CodeMirror-line-like {
    font-feature-settings: inherit;
  }

  .CodeMirror-line, .CodeMirror-line-like {
    font-variant-ligatures: none;
  }
}

