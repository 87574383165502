/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.spinner-border {
  border-color: currentColor;
  border-right-color: transparent;
}

.spinner-grow {
  background-color: currentColor;
}
