/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

//
// Basic Bootstrap table
//

.table {
  color: $table-color;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    border-top-color: $table-border-color;
  }

  thead th {
    border-bottom-color: $table-border-color;
  }

  tbody + tbody {
    border-top-color: $table-border-color;
  }
}


// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {
  border-color: $table-border-color;

  th,
  td {
    border-color: $table-border-color;
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover() {
      color: $table-hover-color;
      background-color: $table-hover-bg;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, $table-bg-level), theme-color-level($color, $table-border-level));
}

@include table-row-variant(active, $table-active-bg);


// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
  .thead-dark {
    th {
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
    }
  }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border-color: $table-border-color;
    }
  }
}

.table-dark {
  color: $table-dark-color;
  background-color: $table-dark-bg;

  th,
  td,
  thead th {
    border-color: $table-dark-border-color;
  }

  &.table-striped {
    tbody tr:nth-of-type(#{$table-striped-order}) {
      background-color: $table-dark-accent-bg;
    }
  }

  &.table-hover {
    tbody tr {
      @include hover() {
        color: $table-dark-hover-color;
        background-color: $table-dark-hover-bg;
      }
    }
  }
}
