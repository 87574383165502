/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #ebebeb;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #888;
$gray-700: #444;
$gray-800: #303030;
$gray-900: #222;
$black: #000;

$blue: #337ab7;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #e74c3c;
$orange: #fd7e14;
$yellow: #f39c12;
$green: #00bc8c;
$teal: #20c997;
$cyan: #5EB7E0;

$primary: $blue;
$secondary: $white;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-900;
$dark: $white;

$yiq-contrasted-threshold: 175;

// Body
$body-bg: $light;
$body-color: $dark;

// Links
$link-color: $cyan;

// Fonts
$text-muted: $gray-400;

// Tables
$table-accent-bg: $gray-800;
$table-border-color: $gray-700;

// Forms
$input-border-color: $body-bg;
$input-group-addon-color: $gray-500;
$input-group-addon-bg: $gray-700;
$input-bg: $gray-700;
$input-placeholder-color: $gray-500;
$input-color: $white;
$input-disabled-bg: $gray-900;

$custom-file-color: $gray-500;
$custom-file-border-color: $body-bg;

// Dropdowns
$dropdown-bg: $gray-900;
$dropdown-border-color: $gray-700;
$dropdown-divider-bg: $gray-700;
$dropdown-link-color: $white;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;

// Navs
$nav-link-disabled-color: $gray-500;
$nav-tabs-border-color: $gray-700;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent;

// Navbar
$navbar-dark-color: rgba($white, .6);
$navbar-dark-hover-color: $white;
$navbar-light-color: rgba($gray-900, .7);
$navbar-light-hover-color: $gray-900;
$navbar-light-active-color: $gray-900;
$navbar-light-toggler-border-color: rgba($gray-900, .1);

// Jumbotron
$jumbotron-bg: $gray-800;

// Cards
$card-cap-bg: $gray-700;
$card-bg: $gray-800;

// Popovers
$popover-bg: $gray-800;
$popover-header-bg: $gray-700;

// Toasts
$toast-background-color: $gray-700;
$toast-header-background-color: $gray-800;

// Modals
$modal-content-bg: $gray-800;
$modal-content-border-color: $gray-700;
$modal-header-border-color: $gray-700;

// Progress bars
$progress-bg: $gray-700;

// List group
$list-group-bg: $gray-800;
$list-group-border-color: $gray-700;
$list-group-hover-bg: $gray-700;

// Breadcrumbs
$breadcrumb-bg: $gray-700;

// Close
$close-color: $white;
$close-text-shadow: none;

// Code
$pre-color: $dark;

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/variables";
