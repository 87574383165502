/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  .arrow {
    &::before {
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  .arrow {
    &::before {
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  .arrow {
    &::before {
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }

  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }

  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }

  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  color: $tooltip-color;
  background-color: $tooltip-bg;
}
