/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

// Inline code
code {
  color: $code-color;
}

kbd {
  color: $kbd-color;
  background-color: $kbd-bg;
  @include box-shadow($kbd-box-shadow);
}

// Blocks of code
pre {
  color: $pre-color;

  code {
    color: inherit;
  }
}
