/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@import "variables.dark";

body.dark {
  @import "bootstrap-color-theme/include";
}
