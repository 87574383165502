/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.close {
  color: $close-color;
  text-shadow: $close-text-shadow;

  @include hover() {
    color: $close-color;
  }
}

button.close {
  background-color: transparent;
}
