/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

// Contextual colors

.text-white {
  color: $white !important;
}

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value, true);
}

.text-body {
  color: $body-color !important;
}

.text-muted {
  color: $text-muted !important;
}

.text-black-50 {
  color: rgba($black, .5) !important;
}

.text-white-50 {
  color: rgba($white, .5) !important;
}
