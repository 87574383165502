/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.progress {
  background-color: $progress-bg;
  @include box-shadow($progress-box-shadow);
}

.progress-bar {
  color: $progress-bar-color;
  background-color: $progress-bar-bg;
}
